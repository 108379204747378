import React from "react";
import Home from './Home'
import Navbar from "./NavBar/Navbar";
import Footer from "./Footer/Footer";


function App() {
  return (
    <>
     <Navbar/>
     <Home/>
      <Footer/>
    </>
  );
}

export default App;
